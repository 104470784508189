<script>

import LineChart from "../CardLineChart";
import DefaultCard_1LineChart from "../default/DefaultCard_1Chart";
import DefaultCard_1AbstractChart from "../default/DefaultCard_1AbstractChart";
import WiFiRelayCard_1Table from "@/components/deviceCards/wifiRelay/WiFiRelayCard_1Table";
import DoubleRelayCard_1Table from "@/components/deviceCards/doubleRelay/DoubleRelayCard_1Table";

export default {
    name: "WiFiRelayCard_1AbstractChart",
    extends: DefaultCard_1AbstractChart,
    components: {
        DefaultCard_1Table: WiFiRelayCard_1Table,
        DefaultCard_1LineChart,
        LineChart
    },
}
</script>
