<template>
    <div :style="{backgroundColor:  chartColor || '#e3e3e3' }" class="gerkon-statistic">
        <div v-for="(item, index) in slicedTimeseries" :key="index" class="mb-1 d-flex align-items-center justify-content-between">
            <div class="text-nowrap">
                <i class="fas fa-calendar-alt"></i>
                <strong> {{ item.date }}</strong>
            </div>
            <div class="text-nowrap">
                <i class="far fa-clock"></i>
                <strong> {{ item.time }}</strong>
            </div>
            <div v-if="item.value !== null">
                <div v-if="item.value === 0" class="text-danger text-center icon-indicator">
                    <i class="fas fa-power-off"></i>
                </div>
                <div v-if="item.value === 1" class="text-success text-center icon-indicator">
                    <i class="fas fa-power-off"></i>
                </div>
            </div>
            <div v-else class="text-muted text-center">
                N/A
            </div>
        </div>
    </div>
</template>

<script>

import config from "../../../config";
import DefaultCard_1AbstractChart from "../default/DefaultCard_1AbstractChart";
import moment from "moment-mini";
import Icon from "../../Icon";

export default {
    name: "DoubleRelayCard_1Table",
    components: {Icon},
    extends: DefaultCard_1AbstractChart,
    computed: {
        slicedTimeseries() {
            const relay = this.timeseries?.find(item => item?.getSlug() === this.metricObject?.getSlug())?.getData()?.slice(0, 6);

            return relay?.map(item => {
                return {
                    date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                    time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                    value: item.getValue(),
                    units: this.metricObject?.getUnits(),
                }
            })
        },
    },
}
</script>

<style scoped lang="less">

.gerkon-statistic {
    border-radius: 10px;
    height: 190px;
    padding: 1rem 1.5rem;
    font-size: 13px;
    color: #fff;

    /*&-item {*/
    /*    margin: 5px 0;*/
    /*}*/

    &-icon {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: white;
    }

    &-time {
        min-width: 80px;
        font-weight: 600;
        line-height: 14px;
    }
    .icon-indicator {
        width: 22px;
        height: 22px;
        background: white;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
