<script>

import Icon from "../../Icon";
import DefaultCard_1MetricsButtons from "../default/DefaultCard_1MetricsButtons";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_1 from "../default/DefaultCard_1";
import WiFiRelayCard_1LatestValue from "@/components/deviceCards/wifiRelay/WiFiRelayCard_1LatestValue";
import WiFiRelayCard_1AbstractChart from "@/components/deviceCards/wifiRelay/WiFiRelayCard_1AbstractChart";

export default {
    name: "WiFiRelayCard_1",
    extends: DefaultCard_1,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: DefaultCard_1MetricsButtons,
        LatestValueComponent: WiFiRelayCard_1LatestValue,
        ChartComponent: WiFiRelayCard_1AbstractChart,
        Icon
    }
}
</script>
