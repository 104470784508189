<template>
    <div>
        <div class="row mb-2" style="min-height: 145px">
            <div class="col-12 position-relative">
                <div class="list-group">
                    <div class="list-group-item cursor-pointer px-2 list-group-item-action font-weight-bold align-items-center"
                         v-for="(metric, index) in paginatedData"
                         :key="index"
                         :class="{ active : isSelected(metric) }"
                         @click="$emit('change', metric)">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div class="d-flex align-items-center">
                                <div class="pr-2">
                                    <div class="position-relative pr-1">
                                        <span v-if="metric.getPortIndex() !== null" class="metric-icon-index">
                                            {{ metric.getPortIndex() + 1 }}
                                        </span>

                                        <Icon :icon-name="statusRelay ? 'double_relay' : 'double_relay_off'"
                                              v-if="metric.getSlug() === 'relay1'"
                                              width="23"
                                              height="23"
                                              :fill="metric.getColor()"/>

                                        <Icon :icon-name="metric.getIcon()"
                                              v-else
                                              width="23"
                                              height="23"
                                              :fill="metric.getColor()"/>
                                    </div>
                                    <Icon v-if="isSelected(metric)" class="d-block mx-auto mt-1"
                                          icon-name="dot"
                                          :fill="isSelected(metric) ? metric.getColor() : ''"
                                          width="4"
                                          height="4"/>
                                    <div v-else class="mt-1" style="height: 4px"></div>
                                </div>
                                <div v-if="metric.getName().length > 20"
                                     class="list-group-item-title"
                                     data-toggle="tooltip"
                                     :title="metric.getName()">
                                    {{ stringLimit(metric.getName(), 20) }}
                                </div>
                                <div v-else class="list-group-item-title">
                                    {{ metric.getName() }}
                                </div>
                            </div>
                            <div>
                                <button
                                    v-confirm="sendCommand"
                                    v-if="metric.getSlug() === 'relay1'"
                                    :disabled="processing"
                                    class="btn btn-sm bg-white"
                                    :class="+getMetricLabel(metric) === 1 ? 'text-danger' : 'text-success'">
                                    <div v-if="processing" class="d-flex align-items-center justify-content-center">
                                        <div class="spinner-border spinner-border-sm mr-1" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                        <div>
                                            {{ $t('Выполнение...') }} {{ counter }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <Icon :icon-name="+getMetricLabel(metric) === 1 ? 'double_relay' : 'double_relay_off'"
                                              width="24"
                                              height="24"
                                              :fill="metric.getColor()"
                                              viewBox="0 0 26 26"/>
                                        {{ +getMetricLabel(metric) === 1 ? $t('Выключить') : $t('Включить') }}
                                    </div>
                                </button>
                                <div class="list-group-item-value" v-else-if="metric.getSlug() === 'motion'">
                                    {{ statusMotion ? $t('Движение') : $t('Нет движения') }}
                                </div>
                                <div class="list-group-item-value" v-else>
                                    {{ getMetricLabel(metric) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty mb-3" style="height: 20px"></div>
    </div>
</template>

<script>

import DefaultCard_3ValuesList from "../default/DefaultCard_3ValuesList";

import DownlinkService from "../../../services/DownlinkService";
import TemporaryOption from "@/classess/TemporaryOption";
import Icon from "../../Icon";

const Downlinks = new DownlinkService();
const TempOption = new TemporaryOption();


export default {
    name: "DoubleRelayCard_3ValuesList",
    extends: DefaultCard_3ValuesList,
    components: {
        Icon,
    },
    inject: ["getInjectedStatusRelay", "getInjectedStatusMotion"],
    data() {
        return {
            processing: false,
            counter: null,
            loop: null,
            tempOptionName: "relay1",
            delay: 10000,
        }
    },
    methods: {
        async sendCommand() {
            this.counter = 10;
            let command;
            this.processing = true;
            TempOption.set(this.tempOptionName, true, this.delay);
            command = this.statusRelay ? 'iks_relay_off' : 'iks_relay_on';
            const result = await Downlinks.sendCommand(this.deviceObject?.getId(), command);

            if (result) {
                this.$toast.info(this.$t('Команда отправлена'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
                TempOption.remove(this.tempOptionName);
            }
            this.done(this.counter)
        },
        done(counter) {
            this.counter = counter;
            this.loop = setInterval(() => {
                this.counter--;

                if ( this.counter <= 0 ) {
                    TempOption.remove(this.tempOptionName);
                    clearInterval(this.loop);
                    this.processing = false;
                    this.$eventBus.emit(this.$eventBus.REFRESH_CARD_TIMESERIES, this.deviceObject.getDevEui());
                    this.$toast.success(this.$t('Команда выполнена'));
                }
            }, 1000);
        }
    },
    computed: {
        statusRelay() {
            return this.getInjectedStatusRelay();
        },
        statusMotion() {
            return this.getInjectedStatusMotion();
        }
    },
    mounted() {
        const isProcessing = TempOption.get(this.tempOptionName, false);
        const optionTtlRelay1 = TempOption.getTtl(this.tempOptionName);

        if (isProcessing) {
            this.processing = true;
            this.done(Math.floor(optionTtlRelay1 / 1000));
        }
    }
}
</script>

<style lang="less" scoped>

@import "../../../less/_variables";

.list-group {
    border-radius: 0;

    &-item {
        display: flex;
        border-color: transparent;
        padding: 0.45rem 1.25rem;
        font-size: 0.9rem;
        border-radius: .5rem;

        &:hover {
            background: white;
        }

        &-title {
            font-weight: 500;
            color: #495057;
        }

        &-value {
            font-weight: 600;
            color: #706C6C;
        }

        &.active {
            color: #495057;
            background: #f1f1f1;
        }

        .btn {
            padding: 0.25rem 0.5rem!important;
        }
    }
}

</style>
