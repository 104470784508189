<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_2 from "../default/DefaultCard_2";
import WiFiRelayCard_2LatestValue from "@/components/deviceCards/wifiRelay/WiFiRelayCard_2LatestValue";
import WiFiRelayCard_1AbstractChart from "@/components/deviceCards/wifiRelay/WiFiRelayCard_1AbstractChart";
import WiFiRelayCard_2MetricsButtons from "@/components/deviceCards/wifiRelay/WiFiRelayCard_2MetricsButtons";

export default {
    name: "WiFiRelayCard_2",
    extends: DefaultCard_2,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: WiFiRelayCard_2MetricsButtons,
        LatestValueComponent: WiFiRelayCard_2LatestValue,
        ChartComponent: WiFiRelayCard_1AbstractChart,
        Icon
    }
}
</script>
