<template>
    <div ref="latestValue" class="latest-value animate__animated">
        <div class="latest-value__bg" :style="metricImage">
            <div v-if="value !== null">
                <div v-if="currentMetric.getSlug() === 'relay1'">
                    <button
                        v-confirm="sendCommand" :disabled="processing"
                        class="btn btn-sm bg-white"
                        :class="+value === 1 ? 'text-danger' : 'text-success'">
                        <div v-if="processing" class="d-flex align-items-center justify-content-center">
                            <div class="spinner-border spinner-border-sm mr-1" role="status">
                                <span class="sr-only"></span>
                            </div>
                            <div>
                                {{ $t('Выполнение...') }} {{ counter }}
                            </div>
                        </div>
                        <div v-else>
                            <Icon :icon-name="+value === 1 ? 'double_relay' : 'double_relay_off'"
                                  width="24"
                                  height="24"
                                  :fill="currentMetric.getColor()"
                                  viewBox="0 0 26 26"/>

                            {{ +value === 1 ? $t('Выключить') : $t('Включить') }}
                        </div>
                    </button>
                </div>
                <div v-else-if="currentMetric.getSlug() === 'motion'">
                    <div class="d-flex justify-content-center align-items-center font-weight-bold mt-1 text-uppercase text-white">
                        {{ statusMotion ? $t('Зафиксировано движение') : $t('Нет движения') }}
                    </div>
                </div>
                <div v-else>
                    <div class="latest-value__value">{{ value }}</div>
                    <div class="latest-value__units">{{ units }}</div>
                </div>
            </div>
            <div v-else>
                <div class="latest-value__value">N/A</div>
                <div class="latest-value__units">{{ units }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import DownlinkService from "@/services/DownlinkService";
import TemporaryOption from "@/classess/TemporaryOption";
import DefaultCard_1LatestValue from "@/components/deviceCards/default/DefaultCard_1LatestValue";
import Icon from "@/components/Icon";

const Downlinks = new DownlinkService();
const TempOption = new TemporaryOption();

export default {
    name: "WiFiRelayCard_1LatestValue",
    extends: DefaultCard_1LatestValue,
    components: {
        Icon
    },
    inject: ["getInjectedStatusRelay", "getInjectedStatusMotion"],
    data() {
        return {
            processing: false,
            counter: null,
            loop: null,
            delay: 10000,
            tempOptionName: 'WiFiRelay',
        }
    },
    methods: {
        async sendCommand() {
            this.counter = 10;
            let command;
            this.processing = true;
            TempOption.set(this.tempOptionName, true, this.delay);
            command = this.statusRelay ? 'iks_relay_off' : 'iks_relay_on';
            const result = await Downlinks.sendCommand(this.deviceObject?.getId(), command);

            if (result) {
                this.$toast.info(this.$t('Команда отправлена'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
                TempOption.remove(this.tempOptionName);
            }
            this.done(this.counter)
        },
        done(counter) {
            this.counter = counter;
            this.loop = setInterval(() => {
                this.counter--;

                if ( this.counter <= 0 ) {
                    TempOption.remove(this.tempOptionName);
                    clearInterval(this.loop);
                    this.processing = false;
                    this.$eventBus.emit(this.$eventBus.REFRESH_CARD_TIMESERIES, this.deviceObject.getDevEui());
                    this.$toast.success(this.$t('Команда выполнена'));
                }
            }, 1000);
        }
    },
    computed: {
        statusRelay() {
            return this.getInjectedStatusRelay();
        },
        statusMotion() {
            return this.getInjectedStatusMotion();
        }
    },
    mounted() {
        const isProcessing = TempOption.get(this.tempOptionName, false);
        const optionTtlRelay1 = TempOption.getTtl(this.tempOptionName);

        if (isProcessing) {
            this.processing = true;
            this.done(Math.floor(optionTtlRelay1 / 1000));
        }
    }
}
</script>
