<script>
import WiFiRelayCard_1 from "./WiFiRelayCard_1";
import WiFiRelayCard_2 from "./WiFiRelayCard_2";
import WiFiRelayCard_3 from "./WiFiRelayCard_3";
import AbstractDefaultCard from "../default/AbstractDefaultCard";


export default {
    name: "AbstractWiFiRelayCard",
    extends: AbstractDefaultCard,
    components: {
        WiFiRelayCard_1,
        WiFiRelayCard_2,
        WiFiRelayCard_3,
    },
    provide() {
        return {
            getInjectedStatusRelay: () => this.statusRelay,
            getInjectedStatusMotion: () => this.statusMotion
        }
    },
    computed: {
        currentCardComponent() {
            switch (this.currentCardDesign) {
                case 3:
                    return WiFiRelayCard_3;
                case 2:
                    return WiFiRelayCard_2;
                default:
                    return WiFiRelayCard_1
            }
        },
        statusRelay() {
            const status = this.timeseries
                ?.find(item => item.getSlug() === 'relay1')
                ?.getLatestData()
                ?.getValue();

            if (status === 1) {
                return true;
            }

            if (status === 0) {
                return false;
            }

            return null;
        },
        statusMotion() {
            const status = this.timeseries
                ?.find(item => item.getSlug() === 'motion')
                ?.getLatestData()
                ?.getValue();

            if (status === 1) {
                return true;
            }

            if (status === 0) {
                return false;
            }

            return null;
        },
    },
}
</script>
