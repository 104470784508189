<template>
    <div ref="latestValue" class="latest-value">
        <div v-if="value !== null">
            <div v-if="currentMetric.getSlug() === 'relay1'"
                 class="d-flex justify-content-center align-content-center text-center">
                <div v-if="currentMetric.getSlug() === 'relay1'">
                    <button
                        v-confirm="sendCommand" :disabled="processing"
                        class="btn btn-sm bg-white"
                        :class="+value === 1 ? 'text-danger' : 'text-success'">
                        <div v-if="processing" class="d-flex align-items-center justify-content-center">
                            <div class="spinner-border spinner-border-sm mr-1" role="status">
                                <span class="sr-only"></span>
                            </div>
                            <div>
                                {{ $t('Выполнение...') }} {{ counter }}
                            </div>
                        </div>
                        <div v-else>
                            <Icon :icon-name="+value === 1 ? 'double_relay' : 'double_relay_off'"
                                  width="24"
                                  height="24"
                                  :fill="currentMetric.getColor()"
                                  viewBox="0 0 26 26"/>

                            {{ +value === 1 ? $t('Выключить') : $t('Включить') }}
                        </div>
                    </button>
                </div>
            </div>
            <div v-else-if="currentMetric.getSlug() === 'motion'">
                <div class="d-flex justify-content-center align-items-center font-weight-bold mt-1 text-uppercase text-white">
                    {{ statusMotion ? $t('Зафиксировано движение') : $t('Нет движения') }}
                </div>
            </div>
            <div v-else>
                <div class="latest-value__value">{{ value }}</div>
                <div class="latest-value__units">{{ units }}</div>
            </div>
        </div>
        <div v-else>
            <div class="latest-value__value">N/A</div>
            <div class="latest-value__units">{{ units }}</div>
        </div>
    </div>
</template>

<script>
import WiFiRelayCard_1LatestValue from "@/components/deviceCards/wifiRelay/WiFiRelayCard_1LatestValue";

export default {
    name: "WiFiRelayCard_2LatestValue",
    extends: WiFiRelayCard_1LatestValue,
}
</script>

<style lang="less" scoped>
.latest-value {
    width: 100%;
    padding: 10px 0 0;


    .status-icon {
        display: inline-block;
        background: #fff;
        padding: 5px 10px;
        border-radius: 5px;
    }

    &__bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #ccc;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }
}

</style>
